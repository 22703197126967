import { UserRole } from '@graphql/generated/types'
import { useRecoilState, useRecoilValue } from 'recoil'

import { childAnalytics, parentAnalytics } from '@/core/services/analytics'
import { session } from '@/core/services/session'

import type { AuthUser } from './atoms'
import {
  authUser as authUserAtom,
  userChildProfile,
  userParentProfile,
} from './atoms'
import { isLogged as isLoggedSelector } from './selectors'

export const useAuthUser = () => {
  const [authUser, setAuthUser] = useRecoilState(authUserAtom)
  const [, setChildProfile] = useRecoilState(userChildProfile)
  const [, setParentProfile] = useRecoilState(userParentProfile)
  const isLogged = useRecoilValue(isLoggedSelector)

  const setUser = (user: NonNullable<AuthUser>) => {
    if (user.role === UserRole.Child) {
      childAnalytics.identify(user._id, user.email, user.username)
    } else {
      parentAnalytics.identify(user._id, user.email, user.username)
    }

    setAuthUser(user)
  }

  const clearUser = () => {
    session.clear()

    if (authUser && authUser.role === UserRole.Child) {
      childAnalytics.emitLogout()
    } else if (authUser && authUser.role === UserRole.Parent) {
      parentAnalytics.emitLogout()

      const scriptId = 'hs-script-loader'
      const script = document.getElementById(scriptId)

      if (script) {
        script.remove()
        const hubspotContainers = document.querySelectorAll(
          "[id^='hubspot-messages-iframe-container']",
        )
        hubspotContainers.forEach((el) => {
          el.remove()
        })
      }
    }

    childAnalytics.reset()
    parentAnalytics.reset()
    setAuthUser(undefined)
    setChildProfile(undefined)
    setParentProfile(undefined)
    if (authUser && authUser.role === UserRole.Parent) {
      window.location.reload()
    }
  }

  return {
    user: authUser,
    isLogged,
    setUser,
    clearUser,
  }
}
