import type { RumInitConfiguration } from '@datadog/browser-rum'
import { datadogRum } from '@datadog/browser-rum'

import type { Site } from './../../../node_modules/@datadog/browser-core/cjs/domain/configuration/intakeSites.d'

const site: Record<Site, Site> = {
  'datadoghq.com': 'datadoghq.com',
  'us3.datadoghq.com': 'us3.datadoghq.com',
  'us5.datadoghq.com': 'us5.datadoghq.com',
  'datadoghq.eu': 'datadoghq.eu',
  'ddog-gov.com': 'ddog-gov.com',
  'ap1.datadoghq.com': 'ap1.datadoghq.com',
}

export const initializeDatadog = () => {
  const {
    VITE_DATADOG_APP_KEY,
    VITE_DATADOG_CLIENT_TOKEN,
    VITE_DATADOG_APP_NAME,
    VITE_DATADOG_ENV,
    VITE_DATADOG_SITE,
    VITE_DATADOG_PRIVACY_LEVEL,
    VITE_DATADOG_SESSION_REPLAY_RATE,
    VITE_DATADOG_SESSION_REPLAY_SAMPLE_RATE,
    VITE_DATADOG_TRACE_SAMPLE_RATE,
    VITE_APM_ENABLED,
  } = import.meta.env

  // Ensure all required environment variables are available
  if (
    !VITE_DATADOG_APP_KEY ||
    !VITE_DATADOG_CLIENT_TOKEN ||
    !VITE_DATADOG_SITE
  ) {
    return
  }

  // Explicitly type the environment variables
  const appKey: string = VITE_DATADOG_APP_KEY as string
  const clientToken: string = VITE_DATADOG_CLIENT_TOKEN as string
  const appName: string | undefined = VITE_DATADOG_APP_NAME as
    | string
    | undefined
  const env: string | undefined = VITE_DATADOG_ENV as string | undefined
  const datadogSite: Site = VITE_DATADOG_SITE as Site
  const privacyLevel: 'allow' | 'mask' | 'mask-user-input' | undefined =
    VITE_DATADOG_PRIVACY_LEVEL as
      | 'allow'
      | 'mask'
      | 'mask-user-input'
      | undefined
  const sampleRate: number | undefined = VITE_DATADOG_SESSION_REPLAY_RATE
    ? parseFloat(VITE_DATADOG_SESSION_REPLAY_RATE)
    : undefined
  const replaySampleRate: number | undefined =
    VITE_DATADOG_SESSION_REPLAY_SAMPLE_RATE
      ? parseFloat(VITE_DATADOG_SESSION_REPLAY_SAMPLE_RATE)
      : undefined
  const traceSampleRate: number | undefined = VITE_DATADOG_TRACE_SAMPLE_RATE
    ? parseFloat(VITE_DATADOG_TRACE_SAMPLE_RATE)
    : undefined

  const config: RumInitConfiguration = {
    applicationId: appKey,
    clientToken,
    site: site[datadogSite],
    service: appName,
    env,
    sessionSampleRate: sampleRate,
    sessionReplaySampleRate: replaySampleRate,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: privacyLevel,
    traceSampleRate,
    allowedTracingUrls: [
      (url) => VITE_APM_ENABLED === 'true' && url.includes('graphql'),
    ],
  }

  datadogRum.init(config)
  datadogRum.startSessionReplayRecording()
}

export const datadogRumInstance = datadogRum
