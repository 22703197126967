import 'react-toastify/dist/ReactToastify.css'

import { useCheckTokenLazyQuery, UserRole } from '@graphql/generated/types'
import { Suspense, useEffect } from 'react'
import { ToastContainer } from 'react-toastify'

import {
  Environment,
  FullscreenLoader,
  UserFeedback,
  UserFeedbackUnauth,
} from '@/components'
import { useLoader } from '@/core/hooks'
import { htmlScripts } from '@/core/services/htmlScript'
import { session } from '@/core/services/session'
import { AppRoutes } from '@/pages/AppRoutes'
import { useApp } from '@/store/app'
import { useAuthUser } from '@/store/user'
import { CssGlobal, CssReset } from '@/styles'

import { datadogRumInstance, initializeDatadog } from './core/services/datadog'

export function App() {
  const { isLogged, user, setUser } = useAuthUser()
  const { setLoading, isLoading } = useApp()
  const { loadingAnimation, loadingPersist } = useLoader(isLoading)

  const endLoading = () => {
    setLoading(false)
  }

  const [checkTokenQuery] = useCheckTokenLazyQuery({
    onCompleted({ checkToken }) {
      setUser(checkToken)

      if (checkToken.role === UserRole.Parent) {
        if (window.ViralLoops) {
          window.ViralLoops.getCampaign().then((campaign) => {
            if (campaign?.identify) {
              campaign.identify({
                email: checkToken.email,
              })
            }
          })
        }
      }

      endLoading()
    },
    onError() {
      endLoading()
    },
  })

  useEffect(() => {
    const { VITE_ENABLE_DATADOG } = import.meta.env

    if (
      VITE_ENABLE_DATADOG === 'true' &&
      !datadogRumInstance.getInternalContext()
    ) {
      initializeDatadog()
    }
  }, [user])

  useEffect(() => {
    if (isLogged) {
      if (user?.role === UserRole.Child) {
        datadogRumInstance.setUser({ email: user.username, id: user._id })
      } else {
        datadogRumInstance.setUser({ email: user?.email, id: user?._id })
      }
    }
  }, [user])

  useEffect(() => {
    htmlScripts.init()

    if (!session.get().accessToken) {
      endLoading()
      return
    }

    checkTokenQuery()
  }, [])

  return (
    <>
      {loadingPersist && (
        <FullscreenLoader isFadeOut={!isLoading} {...loadingAnimation} />
      )}
      <ToastContainer hideProgressBar autoClose={3000} />
      <CssReset />
      <CssGlobal />
      <Suspense fallback={<FullscreenLoader />}>
        {!isLoading && (
          <>
            {user && user.role === UserRole.Child && <UserFeedback />}
            {!user && <UserFeedbackUnauth />}
            <AppRoutes />
          </>
        )}
      </Suspense>
      <Environment />
    </>
  )
}
