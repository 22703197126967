import { useSaveFeedbackMutation } from '@graphql/generated/types'
import { joiResolver } from '@hookform/resolvers/joi/dist/joi'
import html2canvas from 'html2canvas'
import Joi from 'joi'
import { useEffect, useMemo, useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import styled, { css } from 'styled-components'

import { type Option, Button, Icon, Loader } from '@/components'
import { Label } from '@/components/Input/Input.styled'
import { TextArea } from '@/components/Input/TextArea'
import { getUserAgentData } from '@/core/utils/getUserFeedbackRelatedData'
import { wait } from '@/core/utils/wait'
import { validators } from '@/core/validation'
import { useChild } from '@/store/parent/child'
import { media } from '@/styles/media'
import { changeOpacity, fadeInAnimation, flexColumn } from '@/styles/utils'

import { ControlledSelect } from '../Select/ControlledSelect'
import {
  AddScreenshot,
  Header,
  Screenshot,
  ScreenshotDelete,
  Screenshots,
  ScreenshotsContainer,
  Title,
} from './UserFeedback.styled'

export const Open = styled(Button)`
  background-color: ${({ theme }) => theme.color.grape};
`

export const Container = styled.div<{ isVisible: boolean }>`
  padding: 0rem;
  display: ${({ isVisible }) => (isVisible ? 'static' : 'none')};

  &:hover {
    ${Open} {
      opacity: 1;
    }
  }

  ${media.mobile(
    () => css`
      padding: 0.5rem;
    `,
  )}
`

export const ReportForm = styled.form`
  ${flexColumn()};
  background-color: ${({ theme }) => theme.color.white};
  border-radius: 18px;
  box-shadow: inset 2px 4px 20px
    ${({ theme }) => changeOpacity(theme.color.black, 0.25)};
  ${fadeInAnimation()};
  padding: 1rem;
  overflow-y: auto;

  ${media.mobile(
    () => css`
      width: calc(100vw - 2rem);
      height: 80vh;
    `,
  )}
`

export const Close = styled.button`
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`

export const SubmitButton = styled(Button)`
  overflow: unset;
`

const LoadingContainer = styled.div`
  height: 2rem;
`
const screenshotHeight = '4rem'

export const ScreenshotContainer = styled.div`
  position: relative;
  height: ${screenshotHeight};
  width: 100%;
`

export enum TechProblemCategory {
  PlatformIsTooSlow = 'platformIsTooSlow',
  ButtonIsntWorking = 'buttonIsntWorking',
  VideoOrQuizNotLoading = 'videoOrQuizNotLoading',
  DownloadingPrintingNotWorking = 'downloadingPrintingNotWorking',
  SomethingElse = 'somethingElse',
}

// enum ProblemWithQuizCategory {
//   IThinkAnswerIsIncorrect = 'iThinkAnswerIsIncorrect',
//   IFoundTypo = 'iFoundTypo',
//   BtnOrDragNotWorking = 'btnOrDragNotWorking',
//   SomethingElse = 'somethingElse',
// }

// enum GeneralCategory {
//   QuestionBilling = 'questionBilling',
//   UsingPlatform = 'usingPlatform',
//   SomethingElse = 'somethingElse',
// }

export enum TitleCategory {
  NeedHelp = 'needHelp',
  HaveQuestion = 'haveQuestion',
  HaveRequest = 'haveRequest',
}

export enum NeedHelpCategory {
  typoWrongAns = 'typoWrongAns',
  BtnFeature = 'btnFeature',
  SomethingElse = 'somethingElse',
}

export enum QuestionCategory {
  HelpUsingPlatform = 'helpUsingPlatform',
  EducationRelated = 'educationRelated',
  MyBilling = 'myBilling',
}

export enum RequestCategory {
  IdeaCourse = 'ideaCourse',
  Suggest = 'suggest',
  SomethingElse = 'somethingElse',
}

export enum SubmissionCategory {
  FeatureSuggestion = 'featureSuggestion',
  ContentIssueReport = 'contentIssueReport',
  TechnicalIssueReport = 'technicalIssueReport',
  BillingInquiry = 'billingInquiry',
}

export type ScreenshotType = {
  blob: Blob
  src: string
}

type ReportSchema = {
  title: Option<TitleCategory> | undefined
  submissionCategory: Option<SubmissionCategory> | undefined
  message: string
  screenshots: ScreenshotType[]
}

const schema = Joi.object<ReportSchema>({
  title: validators.optionRequired(),
  submissionCategory: validators.optionRequired(),
  message: validators.stringRequired(),
  screenshots: validators.reportScreenshots(),
})

export const maxScreenshotsAllowed = 3

type UserFeedbackInHelpProps = {
  onOpen: VoidFunction
  onClose: VoidFunction
}

export function UserFeedbackInHelp({
  onOpen,
  onClose,
}: UserFeedbackInHelpProps) {
  const { t } = useTranslation('common', {
    keyPrefix: 'userFeedback',
  })

  const [isVisible, setIsVisible] = useState(true)
  const [isOpen, setIsOpen] = useState(false)
  const { current } = useChild()
  const {
    register,
    handleSubmit,
    reset,
    getValues,
    watch,
    setValue,
    control,
    formState: { errors },
  } = useForm<ReportSchema>({
    resolver: joiResolver(schema),
    defaultValues: {
      title: undefined,
      submissionCategory: undefined,
      message: '',
      screenshots: [],
    },
  })

  const { fields, append, remove } = useFieldArray({
    name: 'screenshots',
    control,
  })

  const [saveFeedback, { loading }] = useSaveFeedbackMutation({
    fetchPolicy: 'no-cache',
    onCompleted() {
      toast.success(t('yourFeedbackWasSent'))
      onToggle()
      reset()
    },
  })

  const onToggle = () => {
    setIsOpen((prev) => !prev)
  }

  const onSubmit = ({
    message,
    title,
    screenshots,
    submissionCategory,
  }: ReportSchema) => {
    saveFeedback({
      variables: {
        input: {
          title: title?.label ?? '',
          message,
          screenshots: screenshots.map(
            ({ blob }, index) => new File([blob], `${index}.png`),
          ),
          userAgent: getUserAgentData(),
          url: window.location.href,
          submissionCategoryLabel: submissionCategory?.label ?? '',
          submissionCategoryValue: submissionCategory?.value ?? '',
          selectedStudent: current?._id ?? '',
        },
      },
    })
  }

  useEffect(() => {
    if (isOpen && getValues().screenshots.length === 0) {
      onAddScreenshot()
    }

    if (isOpen) {
      onOpen()
    } else {
      onClose()
    }
  }, [isOpen])

  const onAddScreenshot = async () => {
    setIsVisible(false)
    await wait(100)

    const canvas = await html2canvas(document.body, {
      useCORS: true,
    })
    const response = await fetch(canvas.toDataURL())
    const blob = await response.blob()

    append({
      blob,
      src: URL.createObjectURL(blob),
    })

    setIsVisible(true)
  }

  useEffect(() => {
    setValue('submissionCategory', undefined)
  }, [watch('title'), setValue])

  const submissionCategoryOptions = useMemo(() => {
    const selectedTitle = watch('title')

    if (!selectedTitle) {
      return Object.values([]).map((value) => ({
        label: '',
        value,
      }))
    }

    if (selectedTitle.value === TitleCategory.NeedHelp) {
      return Object.values(NeedHelpCategory).map((value) => ({
        label: t(`${value}`),
        value,
      }))
    }

    if (selectedTitle.value === TitleCategory.HaveQuestion) {
      return Object.values(QuestionCategory).map((value) => ({
        label: t(`${value}`),
        value,
      }))
    }

    if (selectedTitle.value === TitleCategory.HaveRequest) {
      return Object.values(RequestCategory).map((value) => ({
        label: t(`${value}`),
        value,
      }))
    }
  }, [watch('title')])

  const titleCategoryOptions = useMemo(
    () =>
      Object.values(TitleCategory).map((value) => ({
        label: t(`${value}`),
        value,
      })),
    [],
  )

  return (
    <>
      {!isVisible && (
        <LoadingContainer>
          <Loader color="grape" />
        </LoadingContainer>
      )}
      <Container isVisible={isVisible}>
        {isOpen ? (
          <ReportForm onSubmit={handleSubmit(onSubmit)}>
            <Header>
              <Title>{t('report')}</Title>
              <Close
                onClick={() => {
                  onToggle()
                }}
              >
                Close
              </Close>
            </Header>

            <ControlledSelect<ReportSchema>
              control={control}
              name="title"
              label={t('title')}
              error={{
                message: errors.title?.message,
                field: t('title'),
              }}
              options={titleCategoryOptions}
            />

            <ControlledSelect<ReportSchema>
              control={control}
              name="submissionCategory"
              label={t('topic')}
              error={{
                message: errors.submissionCategory?.message,
                field: t('submissionCategory'),
              }}
              options={submissionCategoryOptions}
            />

            <TextArea
              label={t('message')}
              placeholder={t('yourMessage')}
              {...register('message')}
              error={errors.message?.message}
            />
            <ScreenshotsContainer>
              <Label>{t('screenshots')}</Label>
              <Screenshots>
                {fields.map(({ src, id }, index) => (
                  <ScreenshotContainer key={id}>
                    <ScreenshotDelete
                      onClick={(event) => {
                        event.preventDefault()
                        remove(index)
                      }}
                    >
                      <Icon icon="delete" />
                    </ScreenshotDelete>
                    <Screenshot src={src} alt={'screenshot ' + String(index)} />
                  </ScreenshotContainer>
                ))}
                {fields.length < maxScreenshotsAllowed && (
                  <AddScreenshot
                    onClick={(event) => {
                      event.preventDefault()
                      onAddScreenshot()
                    }}
                  >
                    {t('addScreenshot')}
                  </AddScreenshot>
                )}
              </Screenshots>
            </ScreenshotsContainer>
            <SubmitButton thin loading={loading}>
              {t('submit')}
            </SubmitButton>
          </ReportForm>
        ) : (
          <Open rounded thin onClick={onToggle}>
            Submit Help Ticket
          </Open>
        )}
      </Container>
    </>
  )
}
