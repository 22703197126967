import styled, { css } from 'styled-components'

import { Button } from '@/components'
import { media } from '@/styles/media'
import { flexColumn, flexRow, typography } from '@/styles/utils'

export type BgColors = '0' | '1' | '2'

export const SwitcherDiv = styled.div`
  ${flexRow}
  text-align: center;
  justify-content: center;
  margin-bottom: 1rem;
`

export const PlansDiv = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;

  ${media.smallWidth(
    () => css`
      flex-direction: column-reverse;
      gap: 1rem;
    `,
  )}
`

export const BoldItalic = styled.span`
  font-style: italic;
  font-weight: bold;
`

export const PlanDiv = styled.div<{
  isFeatured: boolean
}>`
  border: 1px solid #ccc;
  padding: 0;
  width: 40%;
  box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.3);
  border: ${({ isFeatured, theme }) =>
    (isFeatured && '5px ' + theme.color.purpleHeart + ' solid') || 'unset'};

  ${() => css`
    ${media.smallWidth`
      margin: 0px;
      width: 100%;
    `}
  `}
`

export const PlanTitle = styled.h3<{
  bgColor: BgColors
}>`
  padding: 16px;
  background-color: ${({ bgColor }) =>
    (bgColor === '0' && '#ecda94') ||
    (bgColor === '1' && '#bdd9a5') ||
    (bgColor === '2' && '#a6cdff') ||
    '#a6cdff'};
  text-align: center;
  font-weight: ${({ theme }) => theme.fontWeight.big};
  min-height: 7rem;

  ${media.smallWidth(
    () =>
      css`
        padding: 8px;
        min-height: unset;
      `,
  )}
`

export const PlanPrice = styled.h4`
  padding: 8px;
  font-weight: ${({ theme }) => theme.fontWeight.big};
  font-size: ${({ theme }) => theme.fontSize.great};
`

export const BestValueTag = styled.span`
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  font-size: ${({ theme }) => theme.fontSize.tiny};
  background-color: ${({ theme }) => theme.color.purpleHeart};
  color: ${({ theme }) => theme.color.white};
  padding: 4px 8px;
  display: block;
  text-align: center;
  width: 100%;
`

export const PlanPrePriceText = styled.p`
  padding: 16px 16px 0px 16px;
`

export const PlanPostPriceText = styled.p`
  padding: 16px 16px 16px 16px;
  margin: 0 2rem;
  text-align: center;
`

export const ButtonSelectionSec = styled.div`
  min-width: 10rem;
  ${flexColumn()}
  gap: 0.5rem;
  justify-content: start !important;
  flex: 1 1 0;
  text-align: center;

  ${media.smallWidth(
    () =>
      css`
        max-width: unset;
        min-width: unset;
      `,
  )}
`

export const Subscribe = styled(Button)`
  min-width: 180px;
  height: 44px;

  ${media.smallWidth(
    () =>
      css`
        width: 100%;
        min-width: unset;
      `,
  )}
`

export const FeaturesTitle = styled.h4``

export const ListSec = styled.div`
  padding: 0px 16px 16px 16px;
  ${flexColumn}
  gap: 0.8rem;
  color: gray;
  margin-top: 5px;
`

export const ListItem = styled.li`
  list-style: disc;
`

export const SeeLessMoreButton = styled.button`
  text-align: left;
  color: gray;
  text-decoration: underline;
  margin-top: 5px;
  align-self: center;
`

export const CrossedOutPrice = styled.small`
  font-weight: ${({ theme }) => theme.fontWeight.regular};
`
export const Or = styled.p`
  margin-bottom: 4rem;
  font-size: ${({ theme }) => theme.fontSize.normal};
  font-weight: ${({ theme }) => theme.fontWeight.big};
  margin: 0 0px 0 10px;
  ${media.smallWidth(
    () =>
      css`
        margin: auto;
      `,
  )}
`

export const ButtonsRow = styled.div`
  ${flexRow()}
  gap: 1rem;
  margin: 1rem 0;
  background-size: 60px;
  margin: 0 20px 0 10px;

  ${media.smallWidth(
    () =>
      css`
        ${flexColumn()}
      `,
  )}
`

export const LightSpan = styled.span`
  margin: 0 2rem;
  font-weight: ${({ theme }) => theme.fontWeight.light};
`

export const DarkSpan = styled.span`
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`

export const DarkAndUnderlineSpan = styled.span`
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  text-decoration: underline;
`
export const PriceTitle = styled.p`
  font-size: ${({ theme }) => theme.fontSize.bigger};
  background-color: white;
  border-radius: 1rem;
  width: max-content;
  padding: 2px 10px;
  margin: auto;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
`

export const StyledSectionTitle = styled.h2`
  ${typography.h2};
  text-align: center;
  margin-bottom: 1.5rem;
  margin-top: 3rem;
  font-size: ${({ theme }) => theme.fontSize.huge};
  font-weight: ${({ theme }) => theme.fontWeight.big};
`
