import styled, { css } from 'styled-components'

import lines from '@/assets/images/landing/lines4.svg'
import spiral from '@/assets/images/landing/spiral-orange.svg'
import { Accordion } from '@/components'
import { media } from '@/styles/media'
import { flexColumn } from '@/styles/utils'

export const Main = styled.div`
  margin: 12.5rem 0;
  border-radius: 4rem;
  width: 100%;
  padding: 12.5rem 0;
  background: url(${lines}) no-repeat, url(${spiral}) no-repeat,
    ${({ theme }) => theme.color.aliceBlue};
  position: relative;

  background-position: 5% 90%, 100% 50%;

  ${media.tablet(
    () => css`
      margin: 8rem 0;
      padding: 8rem 1rem;
    `,
  )};

  ${media.tablet(
    () => css`
      background-position: 5% 90%, 104% 25%;
      background-size: 120px 80px, 100px 100px;
    `,
  )};
`
export type AccordionSize = 'Big' | 'Small'

export const FaqContainer = styled.div<{ size: AccordionSize }>`
  ${flexColumn}
  max-width: 75rem;
  width: 100%;
  margin: auto;
  text-align: left;
  gap: ${({ size }) =>
    (size === 'Big' && '2rem') || (size === 'Small' && '1rem')};
`

export const Content = styled.div``

export const StyledAccordion = styled(Accordion)<{ size: AccordionSize }>`
  padding: 0;
  color: ${({ theme }) => theme.color.black};
  border-radius: ${({ size }) =>
    (size === 'Big' && '2rem') || (size === 'Small' && '16px')};
  background-color: ${({ theme }) => theme.color.white};
  box-shadow: ${({ size }) =>
      (size === 'Big' && '8px 8px 0px') || (size === 'Small' && '0px 1px 3px')}
    ${({ theme }) => theme.color.angelInBlueJeans};
`

export const StyledAccordionInner = styled(Accordion)<{ size: AccordionSize }>`
  color: ${({ theme }) => theme.color.black};
  padding: ${({ size }) =>
    (size === 'Big' && '1.5rem 0') || (size === 'Small' && '1rem 0')};
`

export const MainAnswer = styled.div`
  padding: 0 2.125rem;
`

export const Answer = styled.div`
  font-size: ${({ theme }) => theme.landingSpecialFontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.light};
  padding: 0 1rem;

  p {
    margin-top: 0.5rem;
  }

  li {
    list-style: disc;
    margin: 0 1rem;
  }

  strong {
    font-weight: ${({ theme }) => theme.fontWeight.regular};
  }

  ${media.tablet(
    () => css`
      font-size: ${({ theme }) => theme.landingSpecialFontSize.tiny};
    `,
  )};
`

export const Hr = styled.hr`
  border: 1px solid ${({ theme }) => theme.color.blueberryWhip};
`
