import styled, { css } from 'styled-components'

import { Button } from '@/components'
import { Popup } from '@/styles/components'
import { media } from '@/styles/media'
import {
  changeOpacity,
  flexCenter,
  flexColumn,
  flexRow,
  scrollbar,
  transitions,
  typography,
} from '@/styles/utils'

export const Container = styled.div`
  position: relative;

  ${media.mobile(
    () => css`
      display: none;
    `,
  )}
`

export const CartButton = styled.button`
  position: relative;
  ${flexCenter()};
  ${transitions({})('opacity')};

  &:disabled {
    opacity: 0.5;
  }
`

export const StyledButton = styled(Button)`
  width: fit-content;
`

export const StyledPopup = styled(Popup)`
  width: 27rem;
  top: 45px !important;
  padding: 1rem;
  ${flexColumn()};
  gap: 1rem;
  background-color: #f7d400;
`

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Title = styled.h3`
  ${typography.h3};
  font-size: ${({ theme }) => theme.fontSize.great};
`

const closeSize = '1.5rem'

export const Close = styled.button`
  width: ${closeSize};
  height: ${closeSize};
  border-radius: 50%;
  background-color: ${({ theme }) => changeOpacity(theme.color.grape, 0.08)};
  ${flexCenter()};

  svg {
    width: 50%;
    height: 50%;
  }
`

export const Body = styled.div`
  ${flexColumn()};
  gap: 0.5rem;
  // max-height: 70vh;
  overflow-y: auto;
  ${scrollbar('white')};
`

export const Info = styled.div`
  ${flexColumn};
  ${typography.bodyBold};
  justify-content: center;
  align-items: flex-start;
  gap: 1rem;
`

export const RowDiv = styled.div`
  ${flexRow};
  gap: 1rem;
`

export const MiniHeader = styled.p`
  font-size: ${({ theme }) => theme.fontSize.big};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`

export const VideoContainer = styled.div<{ height: string; width: string }>`
  ${({ height, width }) => css`
    height: ${height};
    width: ${width};
  `}

  padding: 2.25rem;
  background-color: ${({ theme }) => theme.color.wildSand};
  border-radius: 18px;
  gap: 2.5rem;

  ${media.tablet(
    () => css`
      width: 500px;
    `,
  )};
`

export const VideoFrame = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 10px;
`
