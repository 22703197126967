import { useTranslation } from 'react-i18next'

import { Icon } from '@/components'
import { usePopup } from '@/core/hooks'
import { datadogRumInstance } from '@/core/services/datadog'
import { mediaPath } from '@/core/services/mediaPath'
import { route } from '@/pages/routes'
import { useChild } from '@/store/parent/child'
import { useCourseDetails } from '@/store/parent/couseDetails'
import { useMarketplaceCart } from '@/store/parent/marketplaceCart'
import { useAuthUser } from '@/store/user'
import { Popup } from '@/styles/components'

import { Container, Image, Link } from './ProfileDropdown.styled'

type ProfileDropdownProps = {
  imageSrc?: string
}

export function ProfileDropdown({ imageSrc }: ProfileDropdownProps) {
  const { t } = useTranslation('parent')
  const { clearUser } = useAuthUser()
  const { clearChild } = useChild()
  const { clearMarketplaceCart } = useMarketplaceCart()
  const { clearCourseDetails } = useCourseDetails()

  const { isOpen, onToggle } = usePopup()

  const onLogout = () => {
    clearUser()
    clearChild()
    clearMarketplaceCart()
    clearCourseDetails()
  }

  return (
    <Container
      onClick={(event) => {
        event.stopPropagation()
        onToggle()
      }}
    >
      {imageSrc ? (
        <Image
          src={mediaPath.build({
            url: imageSrc,
            folderKey: 'userProfilePicture',
          })}
          alt="profile picture"
        />
      ) : (
        <Icon icon="user" />
      )}
      {isOpen && (
        <Popup>
          <Link to={route.parent.profile()}>{t('navbar.editProfile')}</Link>
          <Link
            to=""
            onClick={(event) => {
              event.preventDefault()
              datadogRumInstance.stopSession()
              onLogout()
            }}
          >
            {t('navbar.logout')}
          </Link>
        </Popup>
      )}
    </Container>
  )
}
