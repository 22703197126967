/* eslint-disable complexity */
import { differenceInCalendarDays, format } from 'date-fns'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Loader } from '@/components'
import { globalTrialDays } from '@/core/const'
import { parentAnalytics } from '@/core/services/analytics'
import {
  MsCategory,
  SubscriptionPlanInterval,
  useAnnualPromotionQuery,
  useCreatePaymentSessionMutation,
  useSubscriptionPlansMscBasedQuery,
} from '@/graphql/generated/types'
import { useChild } from '@/store/parent/child'

import {
  LoadingContainer,
  SaveAnyLine,
  TimeWhenBilled,
} from '../SubscribeCard.styled'
import {
  BoldItalic,
  ButtonSelectionSec,
  ButtonsRow,
  CrossedOutPrice,
  DarkSpan,
  FeaturesTitle,
  LightSpan,
  ListItem,
  ListSec,
  PlanDiv,
  PlanPostPriceText,
  PlanPrice,
  PlansDiv,
  PlanTitle,
  PriceTitle,
  SeeLessMoreButton,
  StyledSectionTitle,
  Subscribe,
} from './MSCSubscriptionCards.styled'

export const coreBullets = [
  'allFullAccess',
  'coreAccess',
  'coreMath',
  'coreUnlimited',
  'createCustom',
  'autoPilotScheduling',
  'multimediaLessonsActivities',
  'successCoachCheckIns',
  'annotatableWorksheetsAgain',
  'noAdsAgain',
  'siblingDiscount',
]

export const comboBullets = [
  'allFullAccess',
  'fullAccessAllSubjects',
  'fullAccessFutureReadiness',
  'createCustom',
  'autoPilotScheduling',
  'multimediaLessonsActivities',
  'successCoachCheckIns',
  'annotatableWorksheetsAgain',
  'noAdsAgain',
  'siblingDiscount',
]

export const futureReadinessBullets = [
  'allFullAccess',
  'entrepreneurship101',
  'socialEmotionalLearning',
  'inDepthFinancialLiteracy',
  'safeSocialMedia',
  'smartConsumerism',
  'introToAIChatGPT',
  'investingBorrowing',
  'shoppingOnline',
  'annotatableWorksheetsAgain',
  'noAdsAgain',
  'siblingDiscount',
]

export function MSCSubscriptionCards() {
  const { t } = useTranslation('parent', {
    keyPrefix: 'home',
  })

  const { data: dataMSCPlans, loading: loadingMSCPlans } =
    useSubscriptionPlansMscBasedQuery()
  const { data: dataAnnualPromotion } = useAnnualPromotionQuery()

  const [showAll, setShowAll] = useState(false)

  const handleToggle = () => {
    setShowAll(!showAll)
  }

  const { current } = useChild()

  const [createPaymentSession, { loading: createSessionLoading }] =
    useCreatePaymentSessionMutation({
      onCompleted({ createPaymentSession }) {
        window.location.href = createPaymentSession
      },
    })

  // const extractPrice = (priceString: string) => {
  //   const match = /\$([\d.]+)/.exec(priceString)
  //   return match ? parseFloat(match[1]) : 0
  // }

  const filteredPlans = dataMSCPlans?.subscriptionPlansMSCBased

  const monthlyPlan = dataMSCPlans?.subscriptionPlansMSCBased.find(
    (plan) => plan.interval === SubscriptionPlanInterval.Month,
  )
  let totalFor12Months = 0

  if (monthlyPlan) {
    const monthlyPrice = parseFloat(monthlyPlan.price.replace(/[^0-9.]/g, ''))
    totalFor12Months = parseFloat((monthlyPrice * 12).toFixed(2))
  }

  // let corePlanPrice = 0
  // let futureReadinessPlanPrice = 0

  // filteredPlans?.forEach((plan) => {
  //   if (plan.MSCategory === 'Core') corePlanPrice = extractPrice(plan.price)
  //   if (plan.MSCategory === 'FutureReadiness')
  //     futureReadinessPlanPrice = extractPrice(plan.price)
  // })

  // const comboIdealPrice =
  //   Math.ceil((corePlanPrice + futureReadinessPlanPrice) * 100) / 100

  if (!current) {
    return null
  }

  return (
    <div>
      {/* <SwitcherDiv>
        <StyledStatusLabel active={!isAnnual}>
          {`${t('month')} ${t('billing')}`}
        </StyledStatusLabel>
        <Status
          isAnnual={isAnnual}
          onClick={() => {
            setIsAnnual(!isAnnual)
          }}
        />
        <StyledStatusLabel active={isAnnual}>
          {`${t('yearly')} ${t('billing')}`}
        </StyledStatusLabel>
      </SwitcherDiv> */}

      <StyledSectionTitle>Schoolio Complete</StyledSectionTitle>

      {!loadingMSCPlans && (
        <PlansDiv>
          {filteredPlans?.slice(0, 3).map((plan, index) => (
            <PlanDiv
              key={plan._id}
              isFeatured={
                plan.MSCategory === 'Core' &&
                plan.interval === SubscriptionPlanInterval.Year
              }
            >
              <PlanTitle bgColor={index === 0 ? '0' : index === 1 ? '1' : '2'}>
                {plan.interval === SubscriptionPlanInterval.Year && (
                  <PriceTitle>{plan.postPriceText}</PriceTitle>
                )}

                <PlanPrice>
                  {plan.price}
                  {plan.interval === SubscriptionPlanInterval.Year && (
                    <>
                      {' '}
                      <CrossedOutPrice>
                        <del>${totalFor12Months}</del>
                      </CrossedOutPrice>
                    </>
                  )}

                  {/* {plan.MSCategory === 'Combo' && (
                    <>
                      {' '}
                      <CrossedOutPrice>
                        <del>${comboIdealPrice}</del>
                      </CrossedOutPrice>
                    </>
                  )} */}
                </PlanPrice>

                {plan.MSCategory === MsCategory.Core && (
                  <p>
                    <LightSpan>
                      The perfect plan for families looking for a{' '}
                      <DarkSpan>complete</DarkSpan> home learning solution.
                    </LightSpan>
                  </p>
                )}
              </PlanTitle>
              {plan.MSCategory === MsCategory.Core && (
                <PlanPostPriceText>
                  Enjoy <BoldItalic>unlimited access</BoldItalic> to the
                  complete Schoolio Library! This includes{' '}
                  <BoldItalic>all grades</BoldItalic> of Math, Language Arts,
                  Science, and Social Studies <BoldItalic>and</BoldItalic>{' '}
                  Interest-Based Electives, <BoldItalic>PLUS</BoldItalic> our
                  Future-Readiness Library!
                </PlanPostPriceText>
              )}

              <ButtonsRow>
                {plan.interval === SubscriptionPlanInterval.Year &&
                  dataAnnualPromotion?.annualPromotion &&
                  !dataAnnualPromotion?.annualPromotion.isPersonalPromotion && (
                    <ButtonSelectionSec>
                      <Subscribe
                        loading={createSessionLoading}
                        onClick={(event) => {
                          event.preventDefault()

                          createPaymentSession({
                            variables: {
                              childId: current._id,
                              priceId: plan.stripePriceId,
                              couponId: dataAnnualPromotion.annualPromotion
                                ? dataAnnualPromotion.annualPromotion
                                    .stripeCouponId
                                : undefined,
                            },
                          })
                        }}
                      >
                        {t('startNowSave')}
                      </Subscribe>

                      <SaveAnyLine>
                        {
                          dataAnnualPromotion.annualPromotion
                            .labelForFirstSubscription
                        }
                      </SaveAnyLine>

                      <p>
                        {t('payedToday')} <TimeWhenBilled>today</TimeWhenBilled>
                      </p>
                    </ButtonSelectionSec>
                  )}

                <ButtonSelectionSec>
                  <Subscribe
                    loading={createSessionLoading}
                    onClick={() => {
                      if (
                        current?.isTrialAvailable &&
                        !dataAnnualPromotion?.annualPromotion
                          ?.isPersonalPromotion
                      ) {
                        const trialDays = differenceInCalendarDays(
                          current.trialEndAt
                            ? new Date(current.trialEndAt)
                            : new Date().setDate(
                                new Date().getDate() + globalTrialDays,
                              ),
                          new Date(),
                        )
                        parentAnalytics.emitFreeTrialInit({
                          trialDays,
                          interval: plan.interval,
                        })
                      }

                      createPaymentSession({
                        variables: {
                          childId: current?._id ?? '',
                          priceId: plan.stripePriceId,
                          couponId:
                            dataAnnualPromotion?.annualPromotion
                              ?.isPersonalPromotion &&
                            plan.interval === SubscriptionPlanInterval.Year
                              ? dataAnnualPromotion.annualPromotion
                                  .stripeCouponId
                              : undefined,
                        },
                      })
                    }}
                  >
                    {t(
                      current?.isTrialAvailable
                        ? 'startFreeTrial'
                        : 'subscribe',
                    )}
                  </Subscribe>

                  {current?.isTrialAvailable && current.trialEndAt && (
                    <p>
                      {t('freeUntil')}{' '}
                      <TimeWhenBilled>
                        {format(new Date(current.trialEndAt), 'MMMM do')}
                      </TimeWhenBilled>
                    </p>
                  )}
                  {current?.isTrialAvailable && !current.trialEndAt && (
                    <p>
                      {t('freeUntil')}{' '}
                      <TimeWhenBilled>
                        {format(
                          new Date().setDate(
                            new Date().getDate() + globalTrialDays,
                          ),
                          'MMMM do',
                        )}
                      </TimeWhenBilled>
                    </p>
                  )}

                  {plan.interval === SubscriptionPlanInterval.Year &&
                    dataAnnualPromotion?.annualPromotion &&
                    dataAnnualPromotion.annualPromotion.isPersonalPromotion && (
                      <p>
                        {
                          dataAnnualPromotion.annualPromotion
                            .labelForUpcomingSubscription
                        }
                      </p>
                    )}
                </ButtonSelectionSec>
              </ButtonsRow>
              {plan.MSCategory === MsCategory.Core && (
                <ListSec>
                  {showAll && (
                    <>
                      <FeaturesTitle>Features</FeaturesTitle>

                      {comboBullets.map((bullet) => (
                        <ListItem key={bullet}>{t(`${bullet}`)}</ListItem>
                      ))}
                    </>
                  )}

                  <SeeLessMoreButton onClick={handleToggle}>
                    {showAll ? 'See less...' : 'See more features...'}
                  </SeeLessMoreButton>
                </ListSec>
              )}
            </PlanDiv>
          ))}
        </PlansDiv>
      )}

      {loadingMSCPlans && (
        <LoadingContainer>
          <Loader color="grape" />
        </LoadingContainer>
      )}
    </div>
  )
}
