import { Suspense, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import call from '@/assets/images/landing/call.svg'
import { FullscreenLoader, Icon } from '@/components'
import { schoolioBookStoreUrl } from '@/core/const'
import { adUserManager } from '@/core/services/adUser'
import {
  DiscoverLine,
  FormColumn,
  FreeTrial,
  GetStarted,
  PersonName,
  Quote,
  QuoteColumn,
  SchoolioUniverse,
  SignUpBullet,
  SignUpSalesColumn,
  SignUpSalesDiv,
} from '@/pages/auth/Auth.styled'
import { signupBullets } from '@/pages/auth/AuthContainer'
import { SignUp } from '@/pages/auth/SignUp'

import { PricingComponent } from '../Pricing/PricingComponent'
import { Academics } from './components/Academics'
import { AllInOneSolution } from './components/AllInOneSolution'
import { EducationJourney } from './components/EducationJourney'
import { Faqs } from './components/Faqs'
import { Flexible } from './components/Flexible'
import { Footer } from './components/Footer'
import { Founders } from './components/Founders'
import { Individualize } from './components/Individualize'
import { Library } from './components/Library'
import { Loved } from './components/Loved'
import { Nav } from './components/Nav'
import { PerfectFit } from './components/PerfectFit'
import { SchoolYourWay } from './components/SchoolYourWay'
import { UniqueNeeds } from './components/UniqueNeeds'
import { UnlimitedTrial } from './components/UnlimitedTrial/UnlimitedTrial'
import {
  CallIcon,
  GetHelpContainer,
  GetHelpLine,
  GetHelpLink,
  GetHelpText,
  Main,
  MiniMainSignUp,
} from './Landing.styled'

export function Landing() {
  const { t } = useTranslation('landing')
  const { t: ts } = useTranslation('signUp')

  useEffect(() => {
    adUserManager.handleUrl()
  }, [])

  return (
    <Main>
      <GetHelpLine>
        <GetHelpContainer>
          <GetHelpText>{t('notSureWhereTo')}</GetHelpText>
          <GetHelpLink target="_blank" href={`${schoolioBookStoreUrl}/faq/`}>
            {t('getHelp')}
          </GetHelpLink>
          <CallIcon src={call} alt="call icon" />
        </GetHelpContainer>
      </GetHelpLine>

      <Nav />

      <Suspense
        fallback={
          <FormColumn>
            <FullscreenLoader
              backgroundColor="transparent"
              isAbsolute={false}
            />
          </FormColumn>
        }
      >
        <MiniMainSignUp>
          <SignUpSalesColumn>
            <SignUpSalesDiv>
              <GetStarted>{ts('getStarted')}</GetStarted>
              <FreeTrial>{ts('freeTrial')}</FreeTrial>
              <SchoolioUniverse>{ts('schoolioUni')}</SchoolioUniverse>
              <DiscoverLine>{ts('discover')}</DiscoverLine>

              {signupBullets.map((bullet) => (
                <SignUpBullet key={bullet}>{ts(`${bullet}`)}</SignUpBullet>
              ))}
            </SignUpSalesDiv>
          </SignUpSalesColumn>

          <SignUp />
          {/* <ImageColumn>
            <SideContainer />
          </ImageColumn> */}
          <QuoteColumn>
            <Icon icon="quote" />
            <Quote>{ts('quote')}</Quote>
            <PersonName>{ts('personName')}</PersonName>
          </QuoteColumn>
        </MiniMainSignUp>
      </Suspense>
      <SchoolYourWay />
      <EducationJourney />
      <AllInOneSolution />
      <UnlimitedTrial />
      <Academics />
      <PerfectFit />
      <Individualize />
      <Library />
      <UniqueNeeds />
      <Flexible />
      <PricingComponent />
      {/* <PerfectFor /> */}
      {/* <Trusted /> */}
      <Loved />
      {/* <UnlimitedTrial /> */}
      <Founders />
      <Faqs />
      {/* <QuickStart /> */}
      <Footer />
    </Main>
  )
}
