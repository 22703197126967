import type { ReactNode } from 'react'
import React from 'react'
import styled from 'styled-components'

import { HeaderAccordion } from './components'
import {
  Answer,
  Content,
  FaqContainer,
  Hr,
  StyledAccordionInner,
} from './Faqs.styled'

const MainAnswer = styled.div`
  padding: 0 1.125rem;
  background-color: white;
  border-radius: 2rem;
`

type FAQ = {
  que: string
  ans: ReactNode
}

const faqs1: FAQ[] = [
  {
    que: 'How much does it cost, and what do I get in the plan?',
    ans: `
        <p>
          1. Schoolio Complete
        </p>
        <p>
          This is the perfect plan for families looking for a complete home
          learning solution.
        </p>
        <ul>
          <li>Monthly Subscription: $39.99</li>
          <li>Annual Subscription - SAVE 40%: $279.99</li>
        </ul>
        <p>
          Unlimited access to the entire Schoolio library of courses and all
          grade levels, covering core academics in Math, Language Arts, Science,
          and Social Studies and interest-based electives, PLUS the
          one-of-a-kind Schoolio Future Readiness Library, which includes
          Financial Literacy, Emotional Intelligence & Social Skills, Emerging
          Technologies, Digital Literacy & Online Safety, and Entrepreneurship.
        </p>

        <p>
          2. Enterprise Plans
        </p>
        <p>
          For schools or groups with 10+ students. Contact Schoolio directly for
          custom pricing.
        </p>
    `,
  },
  {
    que: `What happens after my 7-day free trial?`,
    ans: `
      <p>Schoolio content is available via a <strong>subscription model</strong>. By subscribing, you agree that:</p>

  <ul>
    <li>Your subscription <strong>automatically renews</strong> unless canceled before the renewal date.</li>
    <li>You are responsible for all subscription fees until cancellation.</li>
    <li><strong>Schoolio staff cannot cancel subscriptions on your behalf.</strong> You must cancel your subscription yourself before your next billing date. Instructions for canceling can be found at <a href="https://schoolio.com/howtocancel" target="_blank">Schoolio Cancellation Instructions</a>.</li>
  </ul>

    `,
  },
  {
    que: `What is your refund policy?`,
    ans: `
      <p><strong>Schoolio Digital has a strict no-refund policy for all digital products and subscriptions.</strong> All sales are final, and no refunds, credits, or exchanges will be provided once a purchase is completed. By purchasing any digital product or subscription from Schoolio Digital, you acknowledge and agree to this no-refund policy.</p>

  <ul>
    <li><strong>Digital Nature of Products:</strong> Due to the nature of digital products, once access is granted, it cannot be revoked or returned.</li>
    <li><strong>Subscription Cancellations:</strong> Users may cancel subscriptions at any time, but cancellations take effect at the end of the current billing cycle. Partial refunds or prorated refunds are not available.</li>
    <li><strong>Schoolio staff cannot cancel subscriptions on your behalf.</strong> Requests for cancellation by email, social platforms, or live chat are not valid cancellations. You must cancel your subscription yourself before your next billing date. Instructions for canceling can be found at <a href="https://schoolio.com/howtocancel" target="_blank">Schoolio Cancellation Instructions</a>.</li>
    <li><strong>Chargebacks and Disputes:</strong> Any chargeback requests initiated with payment providers will be contested in accordance with these Terms. Users who initiate chargebacks may have their accounts suspended or terminated.</li>
  </ul>

  <p>If you have questions about a purchase, please contact <a href="mailto:info@schoolio.com">info@schoolio.com</a> before completing the transaction.</p>
    `,
  },
  {
    que: 'Is this program suitable for children with learning differences or special needs?',
    ans: `
      <p>Absolutely! Schoolio is designed with neurodivergent learners in mind. Our program is designed in 6 unique ways to meet the most common needs of neurodivergent students, while always being flexible and customizable, making it easier to tailor learning to fit different needs and styles. Whether your child is ADHD, Autistic, or has other learning differences, our resources provide an engaging and personalized education experience.</p>
  
  <p>We recognize that every child’s needs are unique, which is why flexibility and customization are at the heart of our approach. You can read more about how our programming is neurodivergent-friendly at <a href="https://schoolio.com/neurodivergent-homeschool-program/" target="_blank">Schoolio Neurodivergent Homeschool Program</a>.</p>

    `,
  },
  {
    que: 'Is the curriculum accredited? ',
    ans: `
      <p><strong>No homeschool curriculum is ever able to be accredited.</strong> Only institutions operating as schools can be accredited, as accreditation requires strict adherence to content delivery and testing. Schoolio is a homeschool curriculum program- you are the teacher, and we are the tool. Any homeschool program claiming to be accredited is likely accredited only as “supplementary resources” meant for schools.</p>
    `,
  },
  {
    que: "Is it aligned with my state's educational standards?",
    ans: `
      <p>Schoolio is designed as an international curriculum, making it adaptable for use in any country, state, or province. While it is not perfectly aligned with all specific state standards, it is developed with careful consideration of various guidelines, including Common Core, NGSS, TEKS, and other standards from top-performing countries.</p>

  <p>Schoolio aligns well with most standardized learning outcomes, especially in subjects like Mathematics and Language Arts, which follow a consistent progression across regions. Additionally, the curriculum offers both imperial and metric versions to suit your preference and location. In most cases, course options put the Schoolio curriculum above and beyond what is required in typical American schools.</p>

  <p>If strict alignment with your state standards is important, we recommend familiarizing yourself with those guidelines and adjusting the Schoolio units as needed.</p>

  <p>Schoolio provides a complete, high-quality education with well-rounded and customizable materials, ensuring your student receives an excellent learning experience. Many Schoolio 8th grade graduates have gone on to public high school in a range of states and provinces, with full success and easy integration.</p>

    `,
  },
  {
    que: 'Do you offer a discount on multiple students? Scholarships?',
    ans: `
      <p>Yes, we offer a 30% discount for additional students in a household. However, this discount is not automated, so you’ll need to contact our customer service team at <a href="mailto:contact@schoolio.com">contact@schoolio.com</a> to activate it.</p>

  <p>For families or groups with 10 or more students, we also offer Enterprise Pricing—please reach out to inquire about this option.</p>

  <p>Additionally, the ClassWallet ESA Funds option is available in selected states. For more info, visit <a href="https://schoolio.com/classwallet/" target="_blank">Schoolio ClassWallet</a>.</p>

  <p>At this time, we do not have a scholarship program available.</p>

    `,
  },
  {
    que: 'What devices can we use?',
    ans: `<p>Schoolio Digital works best on tablets and computers for an optimal experience. While it can be accessed on mobile phones, the platform is not fully optimized for smaller screens. For smooth navigation and usability, we recommend using a tablet or desktop device.</p>`,
  },
]

type FaqComponent = {
  size?: 'Big' | 'Small'
}

export function FaqsV2(options: FaqComponent) {
  return (
    <FaqContainer size={options.size ?? 'Big'}>
      <MainAnswer>
        {faqs1.map(function (faq, i) {
          return (
            <React.Fragment key={faq.que}>
              <StyledAccordionInner
                disableAnimation
                defaultExpanded={false}
                maxHeight="5000rem"
                size={options.size ?? 'Big'}
                headerComponent={({ isExpanded }) => (
                  <HeaderAccordion
                    headerSize={options.size ?? 'Big'}
                    isExpanded={isExpanded}
                    name={faq.que}
                  />
                )}
              >
                <Content
                  onClick={(event) => {
                    event.stopPropagation()
                  }}
                >
                  <Answer
                    dangerouslySetInnerHTML={{ __html: faq.ans as string }}
                  />
                </Content>
              </StyledAccordionInner>
              {i + 1 < faqs1.length && <Hr />}
            </React.Fragment>
          )
        })}
      </MainAnswer>
    </FaqContainer>
  )
}
