const validDomains = [
  'gmail.com',
  'yahoo.com',
  'outlook.com',
  'hotmail.com',
  'icloud.com',
]

const getLevenshteinDistance = (a: string, b: string): number => {
  const matrix: number[][] = []

  for (let i = 0; i <= b.length; i++) matrix[i] = [i]
  for (let j = 0; j <= a.length; j++) matrix[0][j] = j

  for (let i = 1; i <= b.length; i++) {
    for (let j = 1; j <= a.length; j++) {
      matrix[i][j] =
        b[i - 1] === a[j - 1]
          ? matrix[i - 1][j - 1]
          : Math.min(
              matrix[i - 1][j - 1] + 1,
              matrix[i][j - 1] + 1,
              matrix[i - 1][j] + 1,
            )
    }
  }

  return matrix[b.length][a.length]
}

export const checkEmailDomainWarning = (email: string): string | undefined => {
  const emailParts = email.split('@')
  if (emailParts.length !== 2) return

  const domain = emailParts[1]

  if (!validDomains.includes(domain)) {
    const closestMatch = validDomains.find(
      (validDomain) => getLevenshteinDistance(domain, validDomain) <= 2,
    )

    if (closestMatch) {
      return `⚠️ Did you mean @${closestMatch} instead of @${domain}?`
    }
  }
}
