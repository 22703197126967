import { useIsEmailAvailableLazyQuery } from '@graphql/generated/types'
import { setFieldErrors } from '@graphql/utils/errors'
import { joiResolver } from '@hookform/resolvers/joi'
import * as Joi from 'joi'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import eye from '@/assets/icons/eye.svg'
import eyeSlash from '@/assets/icons/eye-slash.svg'
import type { Option } from '@/components'
import { Button, Checkbox, Input } from '@/components'
import { parentAnalytics } from '@/core/services/analytics'
import { validators } from '@/core/validation'
import { Form, Inputs } from '@/pages/auth/Auth.styled'
import {
  EyeIcon,
  PasswordContainer,
  PasswordInputContainer,
} from '@/pages/auth/SignIn/SignIn.styled'
import { route } from '@/pages/routes'

import { checkEmailDomainWarning } from '../emailUtils'
import {
  Container,
  EmailWarning,
  FlexRow,
  HaveAnAccount,
} from './InitialForm.styled'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const sourceList = [
  { label: 'Word of mouth', value: '4' },
  { label: 'Google', value: '1' },
  { label: 'Facebook/Instagram Ad', value: '2' },
  { label: 'Facebook Group Recommendation', value: '3' },
]

export type LocationDetailsFromIp = {
  city: string
  country: string
  state: string
}

export type InitialFormSchema = {
  firstName?: string | undefined
  lastName?: string | undefined
  email?: string | undefined
  password?: string | undefined
  source?: Option
  termsAndConditionsCombined?: boolean | undefined
  locationDetailsFromIP?: LocationDetailsFromIp
}

const schema = Joi.object<InitialFormSchema>({
  firstName: validators.name(),
  lastName: validators.name(),
  email: validators.email(),
  password: validators.password(),
  source: validators.optionOptional(),
  termsAndConditionsCombined: validators.booleanTrueRequired(),
})

type InitialFormProps = {
  emailValidationError?: string
  onSubmit: (form: InitialFormSchema) => void
  hide: boolean
  isLoading: boolean
}

export function InitialForm({
  onSubmit: onSubmitHandler,
  hide,
  emailValidationError,
  isLoading,
}: InitialFormProps) {
  const { t } = useTranslation('signUp')
  const [showPassword, setShowPassword] = useState(false)
  const {
    register,
    handleSubmit,
    // control,
    setError,
    formState: { errors },
    getValues,
  } = useForm<InitialFormSchema>({
    resolver: joiResolver(schema),
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      termsAndConditionsCombined: true,
    },
  })

  const [emailWarning, setEmailWarning] = useState<string>()

  useEffect(() => {
    if (emailValidationError) {
      setError('email', {
        message: emailValidationError,
      })
    }
  }, [emailValidationError])

  const [isEmailAvailable, { loading: isEmailAvailloading }] =
    useIsEmailAvailableLazyQuery({
      onCompleted() {
        onSubmitHandler(getValues())
      },
      onError({ graphQLErrors }) {
        setFieldErrors(graphQLErrors, setError)
      },
    })

  const onSubmit = (form: InitialFormSchema) => {
    isEmailAvailable({
      variables: {
        email: form.email ?? '',
      },
    })
  }

  return (
    <Container hide={hide}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Inputs>
          <FlexRow>
            <Input
              label={t('form.firstName')}
              placeholder={t('form.firstNamePlaceholder')}
              error={errors.firstName?.message}
              {...register('firstName')}
            />
            <Input
              label={t('form.lastName')}
              placeholder={t('form.lastNamePlaceholder')}
              error={errors.lastName?.message}
              {...register('lastName')}
            />
          </FlexRow>
          <Input
            label={t('form.email')}
            placeholder={t('form.emailPlaceholder')}
            error={errors.email?.message}
            {...register('email')}
            onChange={(e) => {
              setEmailWarning(checkEmailDomainWarning(e.target.value))
            }}
          />
          {emailWarning && (
            <EmailWarning style={{ color: 'orange' }}>
              {emailWarning}
            </EmailWarning>
          )}

          <PasswordContainer>
            <PasswordInputContainer>
              <Input
                label={t('form.password')}
                placeholder={t('form.passwordPlaceholder')}
                error={errors.password?.message}
                type={showPassword ? 'text' : 'password'}
                autoComplete="new-password"
                {...register('password')}
              />
            </PasswordInputContainer>

            <EyeIcon
              src={showPassword ? eyeSlash : eye}
              onClick={() => {
                setShowPassword(!showPassword)
              }}
            />
          </PasswordContainer>

          {/* <ControlledSelect<InitialFormSchema>
            control={control}
            name="source"
            label={t('form.source')}
            error={errors.source?.message}
            options={sourceList}
            appearance="grey"
          /> */}

          <Checkbox
            {...register('termsAndConditionsCombined')}
            error={errors.termsAndConditionsCombined?.message}
          >
            {t('form.agreeToSchoolio')}{' '}
            <Link to={route.termsAndConditions()} target="_blank">
              {t('form.termsAndConditions')}
            </Link>
            {', '}
            <Link to={route.privacyPolicy()} target="_blank">
              {t('form.privacyPolicy')}
            </Link>{' '}
            {t('form.and')}{' '}
            <Link to={route.systemRequirements()} target="_blank">
              {t('form.systemRequirements')}
            </Link>
            .
          </Checkbox>
        </Inputs>
        <Button
          loading={isEmailAvailloading || isLoading}
          onClick={() => {
            parentAnalytics.emitRegisterBtnClick()
          }}
        >
          {t('form.createAccount')}
        </Button>
      </Form>
      <HaveAnAccount>
        {t('haveAnAccount')} <Link to={route.signIn()}>{t('logIn')}</Link>
      </HaveAnAccount>
    </Container>
  )
}
