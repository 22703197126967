import type { WebViewerInstance } from '@pdftron/pdfjs-express'
import webViewer from '@pdftron/pdfjs-express'
import type { Dispatch, SetStateAction } from 'react'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { setHeader } from '@/components/PdfViewer/setHeader'
import { childAnalytics, parentAnalytics } from '@/core/services/analytics'
import { mediaPath } from '@/core/services/mediaPath'
import {
  useGetDownloadDetailsTodayMutation,
  UserRole,
  useUpdateDownloadDetailsMutation,
} from '@/graphql/generated/types'
import { UpgradePlanModal } from '@/pages/parent/dashboard/Home/components/UpgradePlanModal'
import { useChild } from '@/store/parent/child'
import { useAuthUser, useChildProfile } from '@/store/user'

import { ConfirmationModal } from '../Modal/ConfirmationModal/ConfirmationModal'
import { useModalControls } from '../Modal/useModalControls'

const Container = styled.div`
  flex: 1;
`

const disabledElements = [
  'ribbons',
  'selectToolButton',
  'viewControlsButton',
  'searchButton',
  'toggleNotesButton',
  'menuButton',
  'stickyToolGroupButton',
  'highlightToolGroupButton',
  'underlineToolGroupButton',
  'strikeoutToolGroupButton',
  'squigglyToolGroupButton',
  'freeHandHighlightToolGroupButton',
  'textSelectButton',
  'leftPanelButton',
  'toolsHeader',
  'moreButton',
]

const getSrc = (url: string, isAnnotated: boolean) =>
  mediaPath.build({
    url,
    folderKey: isAnnotated ? 'pdf' : 'lesson',
    baseKey: 'pdf',
  })

type PdfViewerProps = {
  src: string
  isAnnotated: boolean
  isFamilyAccount: boolean
  pdfViewer: WebViewerInstance | undefined
  setPdfViewer: Dispatch<SetStateAction<WebViewerInstance | undefined>>
}

export function PdfViewer({
  src,
  isAnnotated,
  setPdfViewer,
  pdfViewer,
  isFamilyAccount,
}: PdfViewerProps) {
  const pdfViewerElementRef = useRef<HTMLDivElement>(null)
  const { isOpen, onOpen, onClose } = useModalControls()
  const [isBackToFullScreen, setIsBackToFullScreen] = useState(false)
  const [seePlans, setSeePlans] = useState(false)
  const [confirmationBtnDisabled, setConfirmationBtnDisabled] =
    useState<boolean>(false)
  const { t } = useTranslation('common')
  const { user } = useAuthUser()
  const { profile: childProfile } = useChildProfile()

  const [getDownloadDetailsToday, { data }] =
    useGetDownloadDetailsTodayMutation()
  const [updateDownloadDetails, { loading }] = useUpdateDownloadDetailsMutation(
    {
      onCompleted: onClose,
    },
  )

  const { current } = useChild()

  useEffect(() => {
    if (!pdfViewerElementRef.current || !user) {
      return
    }

    const isParent = user.role === UserRole.Parent

    webViewer(
      {
        path: '/webviewer',
        licenseKey: import.meta.env.VITE_PDF_VIEWER,
        disabledElements,
      },
      pdfViewerElementRef.current,
    ).then((instance) => {
      const {
        UI,
        Core: { documentViewer, annotationManager },
      } = instance

      setHeader({
        instance,
        isParent,
        isFamilyAccount,
        isOnFreemium:
          Boolean(current?.isOnFreemium) || Boolean(user.isOnFreemium),
        onDownload: async (backToFullScreeen: boolean) =>
          handleDownload(backToFullScreeen),
        onShowPlans() {
          setSeePlans(true)
        },
      })

      documentViewer.addEventListener('documentLoaded', () => {
        setPdfViewer(instance)

        if (!user) {
          return
        }

        if (!isParent) {
          childAnalytics.emitLessonPdfWatched()
          return
        }

        parentAnalytics.emitViewNonAutoGradedWork()
      })

      annotationManager.addEventListener('annotationChanged', () => {
        // eslint-disable-next-line no-empty
        if (!user) {
        }
      })

      UI.loadDocument(getSrc(src, isAnnotated))
    })

    return () => {
      setPdfViewer((prevState) => {
        if (!prevState) {
          return undefined
        }

        prevState.Core.documentViewer.removeEventListener('documentLoaded')
        prevState.Core.annotationManager.removeEventListener(
          'annotationChanged',
        )

        return undefined
      })
    }
  }, [])

  useEffect(() => {
    if (!data) {
      return
    }

    onOpen()

    if (
      data.getDownloadDetailsToday.maxDownloads >
      data.getDownloadDetailsToday.currentDownloads
    ) {
      return
    }

    setConfirmationBtnDisabled(true)
  }, [data])

  const confirmMessage = useMemo(() => {
    if (!data) {
      return
    }

    if (
      data.getDownloadDetailsToday.maxDownloads >
      data.getDownloadDetailsToday.currentDownloads
    ) {
      return t('downloadLeft', {
        ns: 'lesson',
        downloadLeft:
          data.getDownloadDetailsToday.maxDownloads -
          data.getDownloadDetailsToday.currentDownloads,
        maxDownload: data.getDownloadDetailsToday.maxDownloads,
      })
    }

    t('downloadExceeded', {
      ns: 'lesson',
      limit: data.getDownloadDetailsToday.maxDownloads,
    })
  }, [data])

  useEffect(() => {
    if (!pdfViewer) {
      return
    }

    pdfViewer.UI.loadDocument(getSrc(src, isAnnotated))
  }, [src])

  const onModalClose = () => {
    onClose()
    setConfirmationBtnDisabled(false)
    handleZoomIn()
  }

  const onConfirm = () => {
    if (!user) {
      return
    }

    const isParent = user.role === UserRole.Parent

    if (!pdfViewer) {
      return
    }

    if (isParent && current?._id) {
      pdfViewer.UI.downloadPdf()
      updateDownloadDetails({
        variables: { childId: current._id },
      })
      handleZoomIn()
    } else if (!isParent && childProfile) {
      pdfViewer.UI.downloadPdf()
      updateDownloadDetails({
        variables: { childId: childProfile?._id },
      })
      handleZoomIn()
    }
  }

  const handleDownload = async (backToFullScreeen: boolean) => {
    if (!user) {
      return
    }

    const isParent = user.role === UserRole.Parent
    if (current?.isSubscribed) {
      getDownloadDetailsToday({
        variables: { childId: current?._id },
      })
      setIsBackToFullScreen(backToFullScreeen)
    } else if (!isParent && childProfile) {
      getDownloadDetailsToday({
        variables: { childId: childProfile?._id },
      })
      setIsBackToFullScreen(backToFullScreeen)
    } else if (current?.isOnFreemium) {
      setSeePlans(true)
    }
  }

  const handleZoomIn = () => {
    if (isBackToFullScreen) {
      pdfViewer?.UI.toggleFullScreen()
    }
  }

  return (
    <>
      {user && user.role === UserRole.Parent && (
        <UpgradePlanModal
          isOpen={seePlans}
          onClose={() => {
            setSeePlans(false)
          }}
        />
      )}

      <ConfirmationModal
        body={confirmMessage}
        headerLabel={t('downloadHeader', { ns: 'lesson' })}
        cancelLabel={t('cancel')}
        confirmLabel={t('download', { ns: 'lesson' })}
        isOpen={isOpen}
        isConfirmationBtnLoading={loading}
        isConfirmationBtnDisabled={confirmationBtnDisabled}
        onClose={onModalClose}
        onConfirm={onConfirm}
      />
      <Container ref={pdfViewerElementRef} />
    </>
  )
}
