import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import facebook from '@/assets/images/landing/facebook.svg'
import instagram from '@/assets/images/landing/instagram.svg'
import tikTok from '@/assets/images/landing/tik-tok.svg'
import youtube from '@/assets/images/landing/youtube.svg'
import logo from '@/assets/images/logo-word.png'

import {
  aboutMenuItems,
  gradesMenuItems,
  pricingMenuItems,
  programMenuItems,
  successMenuItems,
} from '../Nav'
import {
  ExpandIcon,
  FlexContainer,
  Hr,
  Logo,
  Main,
  MenuItemLinkSmallDevice,
  MenuItemSmallDevice,
  MiddleContainer,
  Rights,
  SmallDeviceMainMenuItems,
  SocialLink,
  SocialMediaContainer,
  SubMenuItemSmallDevice,
  SubMenuLink,
  SubMenuSmallDevice,
  SvgIcon,
} from './Footer.styled'

type Media = {
  url: string
  svg: string
}

type MenuItem = {
  title: string
  items: Array<{
    title: string
    link: string
    newTab?: boolean
  }>
}

const socialMedia: Media[] = [
  {
    url: 'https://www.youtube.com/@schooliolearning',
    svg: youtube,
  },
  {
    url: 'https://www.instagram.com/schooliohomeschooling/',
    svg: instagram,
  },
  {
    url: 'https://www.facebook.com/schooliolearning/',
    svg: facebook,
  },
  {
    url: 'https://www.tiktok.com/@schooliolearning',
    svg: tikTok,
  },
]

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const menuItems: MenuItem[] = [
  { title: 'Programs', items: programMenuItems },
  { title: 'Grades', items: gradesMenuItems },
  { title: 'About', items: aboutMenuItems },
  { title: 'Pricing', items: pricingMenuItems },
  { title: 'Success', items: successMenuItems },
]

export function Footer() {
  const { t } = useTranslation('landing')
  const [expandedMenu, setExpandedMenu] = useState<string | undefined>()

  const toggleMenu = (menu: string) => {
    setExpandedMenu((prevState) => (prevState === menu ? '' : menu))
  }

  return (
    <Main>
      <MiddleContainer>
        <FlexContainer>
          <Logo src={logo} />

          {/* <Links>
            <TitleLinks>Quick Links</TitleLinks>
            {generalLinks.map(function (link) {
              return (
                <Link key={link.title} target="_blank" href={link.url}>
                  {`${link.title}`}
                </Link>
              )
            })}
          </Links>

          <Links>
            <TitleLinks>Program & Features</TitleLinks>
            {featuredLinks.map(function (link) {
              return (
                <Link key={link.title} href={link.url}>
                  {`${link.title}`}
                </Link>
              )
            })}
          </Links> */}

          {/* <MainLinksDiv>
            {menuItems.map((menu) => (
              <div key={menu.title} className="w-1/4">
                <MainTitle>{menu.title}</MainTitle>
                <SubLinksDiv>
                  {menu.items.map((item) => (
                    <SubLink
                      key={item.title}
                      href={item.link}
                      target={item.newTab === false ? '_self' : '_blank'}
                      className="text-blue-600 hover:underline text-sm"
                    >
                      {item.title}
                    </SubLink>
                  ))}
                </SubLinksDiv>
              </div>
            ))}
          </MainLinksDiv> */}

          <SmallDeviceMainMenuItems>
            <MenuItemSmallDevice
              onClick={() => {
                toggleMenu('program')
              }}
            >
              <span>Program</span>
              {expandedMenu === 'program' ? (
                <ExpandIcon>▼</ExpandIcon>
              ) : (
                <ExpandIcon>▶</ExpandIcon>
              )}
            </MenuItemSmallDevice>
            {expandedMenu === 'program' && (
              <SubMenuSmallDevice>
                {programMenuItems.map((item) => (
                  <SubMenuItemSmallDevice key={item.title}>
                    <SubMenuLink
                      href={item.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {item.title}
                    </SubMenuLink>
                  </SubMenuItemSmallDevice>
                ))}
              </SubMenuSmallDevice>
            )}

            <MenuItemLinkSmallDevice
              target="_blank"
              href="https://schoolio.com/features/"
            >
              Features
            </MenuItemLinkSmallDevice>

            <MenuItemSmallDevice
              onClick={() => {
                toggleMenu('grades')
              }}
            >
              <span>Grades</span>
              {expandedMenu === 'grades' ? (
                <ExpandIcon>▼</ExpandIcon>
              ) : (
                <ExpandIcon>▶</ExpandIcon>
              )}
            </MenuItemSmallDevice>
            {expandedMenu === 'grades' && (
              <SubMenuSmallDevice>
                {gradesMenuItems.map((item) => (
                  <SubMenuItemSmallDevice key={item.title}>
                    <SubMenuLink
                      href={item.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {item.title}
                    </SubMenuLink>
                  </SubMenuItemSmallDevice>
                ))}
              </SubMenuSmallDevice>
            )}

            <MenuItemSmallDevice
              onClick={() => {
                toggleMenu('about')
              }}
            >
              <span>About</span>
              {expandedMenu === 'about' ? (
                <ExpandIcon>▼</ExpandIcon>
              ) : (
                <ExpandIcon>▶</ExpandIcon>
              )}
            </MenuItemSmallDevice>
            {expandedMenu === 'about' && (
              <SubMenuSmallDevice>
                {aboutMenuItems.map((item) => (
                  <SubMenuItemSmallDevice key={item.title}>
                    <SubMenuLink
                      href={item.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {item.title}
                    </SubMenuLink>
                  </SubMenuItemSmallDevice>
                ))}
              </SubMenuSmallDevice>
            )}
            <MenuItemSmallDevice
              onClick={() => {
                toggleMenu('pricing')
              }}
            >
              <span>Pricing</span>
              {expandedMenu === 'pricing' ? (
                <ExpandIcon>▼</ExpandIcon>
              ) : (
                <ExpandIcon>▶</ExpandIcon>
              )}
            </MenuItemSmallDevice>
            {expandedMenu === 'pricing' && (
              <SubMenuSmallDevice>
                {pricingMenuItems.map((item) => (
                  <SubMenuItemSmallDevice key={item.title}>
                    <SubMenuLink
                      href={item.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {item.title}
                    </SubMenuLink>
                  </SubMenuItemSmallDevice>
                ))}
              </SubMenuSmallDevice>
            )}
            <MenuItemSmallDevice
              onClick={() => {
                toggleMenu('success')
              }}
            >
              <span>Success</span>
              {expandedMenu === 'success' ? (
                <ExpandIcon>▼</ExpandIcon>
              ) : (
                <ExpandIcon>▶</ExpandIcon>
              )}
            </MenuItemSmallDevice>
            {expandedMenu === 'success' && (
              <SubMenuSmallDevice>
                {successMenuItems.map((item) => (
                  <SubMenuItemSmallDevice key={item.title}>
                    <SubMenuLink
                      href={item.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {item.title}
                    </SubMenuLink>
                  </SubMenuItemSmallDevice>
                ))}
              </SubMenuSmallDevice>
            )}
          </SmallDeviceMainMenuItems>

          <SocialMediaContainer>
            {socialMedia.map(function (socialMedia) {
              return (
                <SocialLink
                  key={socialMedia.url}
                  target="_blank"
                  href={socialMedia.url}
                >
                  <SvgIcon src={socialMedia.svg} />
                </SocialLink>
              )
            })}
          </SocialMediaContainer>
        </FlexContainer>

        <Hr />

        <Rights>{t('copyRights')}</Rights>
      </MiddleContainer>
    </Main>
  )
}
