/* eslint-disable @typescript-eslint/no-unused-vars */
import './translations/i18n'

import { ApolloProvider } from '@apollo/client'
import { client } from '@graphql/apolloClient'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { RecoilRoot } from 'recoil'
import { ThemeProvider } from 'styled-components'

import { App } from './App'
import { theme } from './styles/theme'

// const env = import.meta.env.MODE
// if (env !== 'development') {
//   Sentry.init({
//     dsn: import.meta.env.VITE_SENTRY_DSN,
//     integrations: [new BrowserTracing(), new Sentry.Replay()],
//     environment: env,
//     replaysSessionSampleRate: 0.1,
//     replaysOnErrorSampleRate: 1.0,
//     tracesSampleRate: 1.0,
//     maxBreadcrumbs: 1000,
//     maxValueLength: 4000,
//   })
// }

ReactDOM.createRoot(document.getElementById('root')!).render(
  <RecoilRoot>
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ThemeProvider>
    </ApolloProvider>
  </RecoilRoot>,
)
