import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import hamburger from '@/assets/images/landing/hamburger.png'
import logo from '@/assets/images/logo-with-rocket.png'
import type { LandingPageName } from '@/core/services/trackLandingPages'
import { trackClickLandingPages } from '@/core/services/trackLandingPages'
import { throttle } from '@/core/utils/throttle'
import { AuthRoute } from '@/pages/auth/authRoutes'
import { route } from '@/pages/routes'

import {
  ExpandIcon,
  HamburgerBtn,
  HamburgerLogo,
  LeftLinks,
  Links,
  Logo,
  Main,
  MenuItem,
  MenuItemLink,
  MenuItemLinkSmallDevice,
  MenuItems,
  MenuItemSmallDevice,
  NavSection,
  SignUpLink,
  SmallDeviceMainMenuItems,
  SubMenu,
  SubMenuItem,
  SubMenuItemSmallDevice,
  SubMenuLink,
  SubMenuSmallDevice,
} from './Nav.styled'

type NavProps = {
  viaLandingPage?: LandingPageName
}

export const programMenuItems = [
  { title: 'Our Program', link: 'https://schoolio.com/programdesign/?' },
  { title: 'Core Academics', link: 'https://schoolio.com/core-academics/' },
  { title: 'Future-Readiness', link: 'https://schoolio.com/future-readiness/' },
  {
    title: 'Neurodivergent-Friendly',
    link: 'https://schoolio.com/neurodivergent-homeschool-program/',
  },
  { title: 'Course Catalog', link: 'https://schoolio.com/course-catalog/?' },
  { title: 'Scope & Sequence', link: 'https://schoolio.com/scopeandsequence/' },
  { title: 'Book Lists', link: 'https://schoolio.com/schedule/?#booklists' },
  { title: 'Schedule & Pacing', link: 'https://schoolio.com/schedule/?' },
]

export const gradesMenuItems = [
  { title: 'Kindergarten', link: 'https://schoolio.com/kindergarten/' },
  { title: '1st Grade', link: 'https://schoolio.com/1stgrade/?' },
  { title: '2nd Grade', link: 'https://schoolio.com/2ndgrade/?' },
  { title: '3rd Grade', link: 'https://schoolio.com/3rdgrade/?' },
  { title: '4th Grade', link: 'https://schoolio.com/4thgrade/?' },
  { title: '5th Grade', link: 'https://schoolio.com/5thgrade/?' },
  { title: '6th Grade', link: 'https://schoolio.com/6thgrade/?' },
  { title: '7th Grade', link: 'https://schoolio.com/7thgrade/?' },
  { title: '8th Grade', link: 'https://schoolio.com/8thgrade/?' },
  { title: 'Highschoolers', link: 'https://schoolio.com/highschoolers/?' },
]

export const aboutMenuItems = [
  {
    title: 'Meet the Founders',
    link: 'https://schoolio.com/allaboutschoolio/?',
  },
  {
    title: 'Mission & Vision',
    link: 'https://schoolio.com/allaboutschoolio/?#missionvision',
  },
  {
    title: 'Philosophy of Learning',
    link: 'https://schoolio.com/allaboutschoolio/?#philosophy',
  },
  {
    title: 'Get Involved',
    link: 'https://schoolio.com/allaboutschoolio/?#involved',
  },
  {
    title: 'Competitor Comparison',
    link: 'https://schoolio.com/competitors/?',
  },
  { title: 'In the News', link: 'https://schoolio.com/schooliointhenews/' },
]

export const pricingMenuItems = [
  {
    title: 'Pricing',
    link: '/pricing',
    newTab: false,
  },
  { title: 'ClassWallet ESA', link: 'https://schoolio.com/classwallet/' },
  { title: 'Microschools', link: 'https://schoolio.com/microschools/' },
  {
    title: 'Tutors & Teachers',
    link: 'https://schoolio.com/howtoteachwithschoolio/',
  },
]

export const successMenuItems = [
  {
    title: 'After Sign Up',
    link: 'https://schoolio.com/studentsuccess/?#signup',
  },
  {
    title: 'Custom Program',
    link: 'https://schoolio.com/studentsuccess/?#build',
  },
  {
    title: 'Student Success',
    link: 'https://schoolio.com/studentsuccess/?#studentsuccess',
  },
  {
    title: 'Parent Community',
    link: 'https://schoolio.com/studentsuccess/?#community',
  },
  {
    title: 'Office Hours',
    link: 'https://schoolio.com/studentsuccess/?#officehours',
  },
  {
    title: 'Companion Books',
    link: 'https://schoolio.com/studentsuccess/?#books',
  },
  {
    title: 'Testimonials',
    link: 'https://schoolio.com/schoolio-customer-reviews-and-testimonials/',
  },
]

export function Nav({ viaLandingPage }: NavProps) {
  const [isNavExpanded, setIsNavExpanded] = useState(false)
  const [isNavCardAbsolute, setIsNavCardAbsolute] = useState(false)
  const { pathname } = useLocation()
  const { t } = useTranslation('landing')
  const navigate = useNavigate()
  const [activeMenu, setActiveMenu] = useState<string | undefined>()
  const [expandedMenu, setExpandedMenu] = useState<string | undefined>()

  const isLanding = pathname === AuthRoute.Landing
  const isLogin = pathname === AuthRoute.SignIn

  const onScroll = (event: Event) => {
    if (isNavCardAbsolute) {
      return
    }

    const target = event.target as HTMLBodyElement

    const threshold = 76

    setIsNavCardAbsolute(target.scrollTop > threshold)
  }

  const toggleMenu = (menu: string) => {
    setExpandedMenu((prevState) => (prevState === menu ? '' : menu))
  }

  const throttledOnScroll = useMemo(() => throttle(onScroll, 25), [])

  useEffect(() => {
    document.body.addEventListener('scroll', throttledOnScroll)
    return () => {
      document.body.removeEventListener('scroll', throttledOnScroll)
    }
  }, [])

  const logoClicked = () => {
    navigate('/')
  }

  const signUp = () => {
    if (viaLandingPage) {
      trackClickLandingPages.handleClick(viaLandingPage)
    }

    document.documentElement.scrollTop = 0
    document.body.scrollTop = 0
    navigate(route.landing())
  }

  return (
    <NavSection isAbsolute={isNavCardAbsolute} isNavExpanded={isNavExpanded}>
      <Main isAbsolute={isNavCardAbsolute}>
        <LeftLinks>
          <Logo src={logo} alt="schoolio logo" onClick={logoClicked} />
          <MenuItems isNavExpanded={isNavExpanded}>
            <MenuItem
              onMouseEnter={() => {
                setActiveMenu('program')
              }}
              onMouseLeave={() => {
                setActiveMenu('')
              }}
            >
              Program
              {activeMenu === 'program' && (
                <SubMenu className="submenu">
                  {programMenuItems.map((item) => (
                    <SubMenuItem
                      key={item.title}
                      href={item.link}
                      target="_blank"
                    >
                      {item.title}
                    </SubMenuItem>
                  ))}
                </SubMenu>
              )}
            </MenuItem>
            <MenuItem>
              <MenuItemLink
                target="_blank"
                href="https://schoolio.com/features/"
              >
                Features
              </MenuItemLink>
            </MenuItem>
            <MenuItem
              onMouseEnter={() => {
                setActiveMenu('grades')
              }}
              onMouseLeave={() => {
                setActiveMenu('')
              }}
            >
              Grades
              {activeMenu === 'grades' && (
                <SubMenu className="submenu">
                  {gradesMenuItems.map((item) => (
                    <SubMenuItem
                      key={item.title}
                      href={item.link}
                      target="_blank"
                    >
                      {item.title}
                    </SubMenuItem>
                  ))}
                </SubMenu>
              )}
            </MenuItem>
            <MenuItem
              onMouseEnter={() => {
                setActiveMenu('about')
              }}
              onMouseLeave={() => {
                setActiveMenu('')
              }}
            >
              About
              {activeMenu === 'about' && (
                <SubMenu className="submenu">
                  {aboutMenuItems.map((item) => (
                    <SubMenuItem
                      key={item.title}
                      href={item.link}
                      target="_blank"
                    >
                      {item.title}
                    </SubMenuItem>
                  ))}
                </SubMenu>
              )}
            </MenuItem>
            <MenuItem
              onMouseEnter={() => {
                setActiveMenu('pricing')
              }}
              onMouseLeave={() => {
                setActiveMenu('')
              }}
            >
              Pricing
              {activeMenu === 'pricing' && (
                <SubMenu className="submenu">
                  {pricingMenuItems.map((item) => (
                    <SubMenuItem
                      key={item.title}
                      href={item.link}
                      target={item.newTab === false ? '_self' : '_blank'}
                    >
                      {item.title}
                    </SubMenuItem>
                  ))}
                </SubMenu>
              )}
            </MenuItem>
            <MenuItem
              onMouseEnter={() => {
                setActiveMenu('success')
              }}
              onMouseLeave={() => {
                setActiveMenu('')
              }}
            >
              Success
              {activeMenu === 'success' && (
                <SubMenu className="submenu">
                  {successMenuItems.map((item) => (
                    <SubMenuItem
                      key={item.title}
                      href={item.link}
                      target="_blank"
                    >
                      {item.title}
                    </SubMenuItem>
                  ))}
                </SubMenu>
              )}
            </MenuItem>
          </MenuItems>
        </LeftLinks>

        <HamburgerBtn
          onClick={() => {
            setIsNavExpanded(!isNavExpanded)
          }}
        >
          <HamburgerLogo src={hamburger} alt="menu icon" />
        </HamburgerBtn>

        <Links isNavExpanded={isNavExpanded}>
          {/* <Link href="/pricing">{t('pricing')}</Link> */}
          {/* <Hr isNavExpanded={isNavExpanded} /> */}

          {/* <Link target="_blank" href={`${schoolioBookStoreUrl}course-catalog/`}>
            {t('courses')}
          </Link> */}

          <SmallDeviceMainMenuItems>
            <MenuItemSmallDevice
              onClick={() => {
                toggleMenu('program')
              }}
            >
              <span>Program</span>
              {expandedMenu === 'program' ? (
                <ExpandIcon>▼</ExpandIcon>
              ) : (
                <ExpandIcon>▶</ExpandIcon>
              )}
            </MenuItemSmallDevice>
            {expandedMenu === 'program' && (
              <SubMenuSmallDevice>
                {programMenuItems.map((item) => (
                  <SubMenuItemSmallDevice key={item.title}>
                    <SubMenuLink
                      href={item.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {item.title}
                    </SubMenuLink>
                  </SubMenuItemSmallDevice>
                ))}
              </SubMenuSmallDevice>
            )}
            <MenuItemLinkSmallDevice
              target="_blank"
              href="https://schoolio.com/features/"
            >
              Features
            </MenuItemLinkSmallDevice>

            <MenuItemSmallDevice
              onClick={() => {
                toggleMenu('grades')
              }}
            >
              <span>Grades</span>
              {expandedMenu === 'grades' ? (
                <ExpandIcon>▼</ExpandIcon>
              ) : (
                <ExpandIcon>▶</ExpandIcon>
              )}
            </MenuItemSmallDevice>
            {expandedMenu === 'grades' && (
              <SubMenuSmallDevice>
                {gradesMenuItems.map((item) => (
                  <SubMenuItemSmallDevice key={item.title}>
                    <SubMenuLink
                      href={item.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {item.title}
                    </SubMenuLink>
                  </SubMenuItemSmallDevice>
                ))}
              </SubMenuSmallDevice>
            )}
            <MenuItemSmallDevice
              onClick={() => {
                toggleMenu('about')
              }}
            >
              <span>About</span>
              {expandedMenu === 'about' ? (
                <ExpandIcon>▼</ExpandIcon>
              ) : (
                <ExpandIcon>▶</ExpandIcon>
              )}
            </MenuItemSmallDevice>
            {expandedMenu === 'about' && (
              <SubMenuSmallDevice>
                {aboutMenuItems.map((item) => (
                  <SubMenuItemSmallDevice key={item.title}>
                    <SubMenuLink
                      href={item.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {item.title}
                    </SubMenuLink>
                  </SubMenuItemSmallDevice>
                ))}
              </SubMenuSmallDevice>
            )}
            <MenuItemSmallDevice
              onClick={() => {
                toggleMenu('pricing')
              }}
            >
              <span>Pricing</span>
              {expandedMenu === 'pricing' ? (
                <ExpandIcon>▼</ExpandIcon>
              ) : (
                <ExpandIcon>▶</ExpandIcon>
              )}
            </MenuItemSmallDevice>
            {expandedMenu === 'pricing' && (
              <SubMenuSmallDevice>
                {pricingMenuItems.map((item) => (
                  <SubMenuItemSmallDevice key={item.title}>
                    <SubMenuLink
                      href={item.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {item.title}
                    </SubMenuLink>
                  </SubMenuItemSmallDevice>
                ))}
              </SubMenuSmallDevice>
            )}
            <MenuItemSmallDevice
              onClick={() => {
                toggleMenu('success')
              }}
            >
              <span>Success</span>
              {expandedMenu === 'success' ? (
                <ExpandIcon>▼</ExpandIcon>
              ) : (
                <ExpandIcon>▶</ExpandIcon>
              )}
            </MenuItemSmallDevice>
            {expandedMenu === 'success' && (
              <SubMenuSmallDevice>
                {successMenuItems.map((item) => (
                  <SubMenuItemSmallDevice key={item.title}>
                    <SubMenuLink
                      href={item.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {item.title}
                    </SubMenuLink>
                  </SubMenuItemSmallDevice>
                ))}
              </SubMenuSmallDevice>
            )}
          </SmallDeviceMainMenuItems>

          <SignUpLink href="https://calendly.com/lindsey-schoolio/digital-walkthrough">
            Request a Demo
          </SignUpLink>
          {isLanding && <SignUpLink href="/sign-in">{t('logIn')}</SignUpLink>}
          {!isLanding && !isLogin && (
            <SignUpLink href="/sign-in">{t('logIn')}</SignUpLink>
          )}
          {!isLanding && (
            <SignUpLink
              href="/"
              onClick={(e) => {
                e.preventDefault()
                signUp()
              }}
            >
              {t('signUp')}
            </SignUpLink>
          )}
        </Links>
      </Main>
    </NavSection>
  )
}
