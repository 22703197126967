import { useNavigate } from 'react-router-dom'

import adhdDesigned from '@/assets/images/landing/adhd-designed.webp'
import autoGrading from '@/assets/images/landing/autograding.png'
import calendar from '@/assets/images/landing/calendar-landing.webp'
import customCourse from '@/assets/images/landing/custom-course.webp'
import dashboard from '@/assets/images/landing/dashboard.webp'
import dino from '@/assets/images/landing/dino.webp'
import marketplace from '@/assets/images/landing/marketplace.webp'
import testimonial1 from '@/assets/images/landing/testimonial-1.webp'
import testimonial2 from '@/assets/images/landing/testimonial-2.webp'
import testimonial3 from '@/assets/images/landing/testimonial-3.webp'
import {
  LandingPageName,
  trackClickLandingPages,
} from '@/core/services/trackLandingPages'
import { PricingComponent } from '@/pages/common/Pricing/PricingComponent'
import { route } from '@/pages/routes'

import { MaxWidthContainer, TrialButton } from '../../LandingUSA.styled'
import {
  AnchorLink,
  IndiImage,
  Main,
  MainTitle,
  MiniTitle,
  ReviewContain,
  SectionImage,
  TestimonialImage,
  Title,
  UserTestimonial,
} from './ImagesComponent.styled'

export function ImagesComponent() {
  const navigate = useNavigate()

  const signUp = () => {
    trackClickLandingPages.handleClick(LandingPageName.Welcome)
    document.documentElement.scrollTop = 0
    document.body.scrollTop = 0
    navigate(route.landing())
  }

  return (
    <MaxWidthContainer>
      <Main>
        <SectionImage>
          <MainTitle>Everything You Need for Success!</MainTitle>
          <Title>All Subjects and All Grades in One Place!</Title>
          <IndiImage src={marketplace} alt="Image" loading="lazy" />
          <TrialButton onClick={signUp}>
            Start 7-day Free Trial
            <span>*Cancel Anytime | 100% Refunds</span>
          </TrialButton>
        </SectionImage>

        <SectionImage>
          <Title>Scheduling Made Easy!</Title>
          <IndiImage src={calendar} alt="Image" loading="lazy" />
          <Title>Dashboard, Grades & Transcripts!</Title>
          <IndiImage src={dashboard} alt="Image" loading="lazy" />

          <TrialButton onClick={signUp}>
            Start 7-day Free Trial
            <span>*Cancel Anytime | 100% Refunds</span>
          </TrialButton>
        </SectionImage>

        <SectionImage>
          <MainTitle>What Makes Schoolio Unique</MainTitle>
          <Title>Create Custom Courses!</Title>
          <IndiImage src={customCourse} alt="Image" loading="lazy" />
          <Title>Written Work Auto Grading!</Title>
          <IndiImage src={autoGrading} alt="Image" loading="lazy" />
          <MiniTitle>New Features Released Every Week</MiniTitle>

          <TrialButton onClick={signUp}>
            Start 7-day Free Trial
            <span>*Cancel Anytime | 100% Refunds</span>
          </TrialButton>
        </SectionImage>

        <SectionImage>
          <Title>Child-Centered Learning</Title>
          <IndiImage src={dino} alt="Image" loading="lazy" />
          <Title>Designed for ADHD & Autistic Learners</Title>
          <IndiImage src={adhdDesigned} alt="Image" loading="lazy" />

          <TrialButton onClick={signUp}>
            Start 7-day Free Trial
            <span>*Cancel Anytime | 100% Refunds</span>
          </TrialButton>
        </SectionImage>

        <SectionImage>
          <Title>Schoolio Love!</Title>
          <ReviewContain>
            We know you have options when it comes to your home learning
            provider, and we appreciate your trust in us. You can see how we
            compare to{' '}
            <AnchorLink href="https://schoolio.com/competitors/?">
              Competitors
            </AnchorLink>{' '}
            like{' '}
            <AnchorLink href="https://schoolio.com/competitors/?#miacademy">
              MiAcademy
            </AnchorLink>
            ,{' '}
            <AnchorLink href="https://schoolio.com/competitors/?#ixl">
              IXL
            </AnchorLink>
            , and{' '}
            <AnchorLink href="https://schoolio.com/competitors/?#time4learning">
              Time4Learning
            </AnchorLink>{' '}
            so you feel fully informed in your choice.
          </ReviewContain>
          <UserTestimonial>
            <TestimonialImage src={testimonial1} alt="Image" loading="lazy" />
            <TestimonialImage src={testimonial2} alt="Image" loading="lazy" />
            <TestimonialImage src={testimonial3} alt="Image" loading="lazy" />
          </UserTestimonial>
          <TrialButton onClick={signUp}>
            Start 7-day Free Trial
            <span>*Cancel Anytime | 100% Refunds</span>
          </TrialButton>
        </SectionImage>

        <PricingComponent viaLandingPage={LandingPageName.Welcome} />
      </Main>
    </MaxWidthContainer>
  )
}
