import { ApolloClient, ApolloLink, from, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import type { FetchResult } from '@apollo/client/link/core/types'
import { onError } from '@apollo/client/link/error'
import { Observable } from '@apollo/client/utilities'
import type {
  RefreshMutation,
  SignInMutation,
  SignUpMutation,
  VerifyEmailMutation,
} from '@graphql/generated/types'
import {
  getErrorMessage,
  getIsRefreshTokenError,
  getIsUnauthenticatedError,
} from '@graphql/utils/errors'
import { updateTokens } from '@graphql/utils/updateTokens'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as Sentry from '@sentry/react'
import { createUploadLink } from 'apollo-upload-client'
import { toast } from 'react-toastify'

import { session } from '@/core/services/session'
import { TokensEmptyError } from '@/core/utils/errors'
import { i18n } from '@/translations/i18n'

export enum AuthQuery {
  SignIn = 'signIn',
  Refresh = 'refresh',
  VerifyEmail = 'verifyEmail',
  SignUp = 'signUp',
}

const authLink = setContext((operation, { headers }) => {
  const { accessToken, refreshToken } = session.get()
  const token =
    operation.operationName === AuthQuery.Refresh ? refreshToken : accessToken

  return {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
      // eslint-disable-next-line new-cap
      'client-timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
  }
})

const errorLink = onError((error) => {
  if (getIsRefreshTokenError(error)) {
    return
  }

  if (!getIsUnauthenticatedError(error)) {
    const defaultMessageKey = 'networkError'

    const errorMessageKey = getErrorMessage(error) ?? defaultMessageKey
    const message = i18n.t(errorMessageKey, {
      ns: 'error',
    })

    if (message) {
      toast.error(message)
    }

    // Sentry.captureException(JSON.stringify(error))
    return
  }

  return new Observable<FetchResult>((observer) => {
    const { forward, operation } = error

    updateTokens({ client, ...error })
      .then(() => {
        const subscriber = {
          next: observer.next.bind(observer),
          error: observer.error.bind(observer),
          complete: observer.complete.bind(observer),
        }

        forward(operation).subscribe(subscriber)
      })
      .catch((error) => {
        observer.error(error)
      })
  })
})

const setTokensLink = new ApolloLink((operation, forward) => {
  const authQueries = [
    AuthQuery.SignIn,
    AuthQuery.Refresh,
    AuthQuery.VerifyEmail,
    AuthQuery.SignUp,
  ]

  if (authQueries.includes(operation.operationName as AuthQuery)) {
    return forward(operation).map((response) => {
      let signIn = response.data?.signIn as SignInMutation['signIn']
      let signUp

      if (response.data?.verifyEmail) {
        signIn = response.data
          ?.verifyEmail as VerifyEmailMutation['verifyEmail']
      } else if (response.data?.signUp) {
        signUp = response.data.signUp as SignUpMutation['signUp']
      }

      const refresh = response.data?.refresh as RefreshMutation['refresh']

      if (response.errors) {
        return response
      }

      const { accessToken, refreshToken } = {
        ...signIn,
        ...signUp,
        ...refresh,
      }

      if (!accessToken || !refreshToken) {
        session.clear()
        throw new TokensEmptyError()
      }

      session.set(accessToken, refreshToken)

      return response
    })
  }

  return forward(operation)
})

export const client = new ApolloClient({
  link: from([
    setTokensLink,
    errorLink,
    authLink,
    createUploadLink({
      uri: `${import.meta.env.VITE_API_URL}/graphql`,
    }),
  ]),
  cache: new InMemoryCache(),
})
