import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Icon, Modal } from '@/components'
import { useModalControls } from '@/components/Modal/useModalControls'
import { UserFeedbackInHelp } from '@/components/UserFeedback/UserFeedbackInHelp'
import { usePopup } from '@/core/hooks'
import { route } from '@/pages/routes'

import {
  Body,
  CartButton,
  Close,
  Container,
  Header,
  RowDiv,
  StyledButton,
  StyledPopup,
  Title,
  VideoContainer,
  VideoFrame,
} from './Help.styled'

export function Help() {
  const { isOpen, onToggle } = usePopup()
  const navigate = useNavigate()
  const {
    onOpen: onVideoOpen,
    onClose: onVideoClose,
    isOpen: isVideoOpen,
  } = useModalControls()
  const [isTicketOpen, setIsTicketOpen] = useState(false)

  return (
    <Container
      onClick={(event) => {
        event.stopPropagation()
      }}
    >
      <CartButton onClick={onToggle}>
        <Icon icon="questionCircle" />
      </CartButton>
      {isOpen && (
        <StyledPopup>
          <Header>
            <Title>Need help?</Title>
            <Close onClick={onToggle}>
              <Icon icon="close" />
            </Close>
          </Header>
          <Body>
            <RowDiv>
              {!isTicketOpen && (
                <>
                  <StyledButton
                    rounded
                    thin
                    onClick={() => {
                      onVideoOpen()
                    }}
                  >
                    Full Demo
                  </StyledButton>
                  <StyledButton
                    rounded
                    thin
                    onClick={() => {
                      navigate(route.parent.resources())
                      onToggle()
                    }}
                  >
                    Resources
                  </StyledButton>
                </>
              )}
              <UserFeedbackInHelp
                onOpen={() => {
                  setIsTicketOpen(true)
                }}
                onClose={() => {
                  setIsTicketOpen(false)
                }}
              />
            </RowDiv>
          </Body>
        </StyledPopup>
      )}

      <Modal
        shouldCloseOnOverlayClick
        isOpen={isVideoOpen}
        closePosition={{
          top: '1rem',
          right: '1rem',
        }}
        onRequestClose={() => {
          onVideoClose()
        }}
      >
        <VideoContainer width="700px" height="450px">
          <VideoFrame
            allowFullScreen
            src="https://www.youtube.com/embed/OAAeKt8ndwU"
            title="YouTube video player"
            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          />
        </VideoContainer>
      </Modal>
    </Container>
  )
}
